<template>
   <b-modal id="previous-okr" hide-footer>
      <template #modal-title #modal-header="{ close }">
        <span>Previous OKR’s</span>
        <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20" @click="$bvModal.hide('previous-okr')" />
      </template>
      <div class="previous-okr">
          <div class="search-okr">
            <!-- <div class="d-flex align-items-center gap-2">
              <div class="filter">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path d="M9.00391 3.25065C9.00391 3.75898 9.20584 4.24649 9.56529 4.60594C9.92473 4.96538 10.4122 5.16732 10.9206 5.16732C11.4289 5.16732 11.9164 4.96538 12.2759 4.60594C12.6353 4.24649 12.8372 3.75898 12.8372 3.25065C12.8372 2.74232 12.6353 2.25481 12.2759 1.89536C11.9164 1.53592 11.4289 1.33398 10.9206 1.33398C10.4122 1.33398 9.92473 1.53592 9.56529 1.89536C9.20584 2.25481 9.00391 2.74232 9.00391 3.25065Z" stroke="#B1B1B1" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M1.33594 3.25H9.0026" stroke="#B1B1B1" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12.8359 3.25H16.6693" stroke="#B1B1B1" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M3.25391 9.00065C3.25391 9.50898 3.45584 9.99649 3.81528 10.3559C4.17473 10.7154 4.66224 10.9173 5.17057 10.9173C5.6789 10.9173 6.16642 10.7154 6.52586 10.3559C6.8853 9.99649 7.08724 9.50898 7.08724 9.00065C7.08724 8.49232 6.8853 8.00481 6.52586 7.64536C6.16642 7.28592 5.6789 7.08398 5.17057 7.08398C4.66224 7.08398 4.17473 7.28592 3.81528 7.64536C3.45584 8.00481 3.25391 8.49232 3.25391 9.00065Z" stroke="#B1B1B1" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M1.33594 9H3.2526" stroke="#B1B1B1" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7.08594 9H16.6693" stroke="#B1B1B1" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11.8789 14.7507C11.8789 15.259 12.0808 15.7465 12.4403 16.1059C12.7997 16.4654 13.2872 16.6673 13.7956 16.6673C14.3039 16.6673 14.7914 16.4654 15.1509 16.1059C15.5103 15.7465 15.7122 15.259 15.7122 14.7507C15.7122 14.2423 15.5103 13.7548 15.1509 13.3954C14.7914 13.0359 14.3039 12.834 13.7956 12.834C13.2872 12.834 12.7997 13.0359 12.4403 13.3954C12.0808 13.7548 11.8789 14.2423 11.8789 14.7507Z" stroke="#B1B1B1" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M1.33594 14.75H11.8776" stroke="#B1B1B1" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15.7109 14.75H16.6693" stroke="#B1B1B1" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div class="position-relative search-posthead w-100">
                <b-input-group class="input-group-merge round">
                  <b-form-input class="d-inline-block" placeholder="Search Objective or Keyresult" />
                </b-input-group>
              </div>
            </div> -->
            <ul class="my-1 p-0">
              <li class="list-unstyled list-ofokr my-1" v-for="objective in previousOKRs">
                <b-form-checkbox
                  :id="'objective-'+objective.hashid"
                  v-model="selectedObjectives"
                  :name="'objective-'+objective.hashid"
                  :value="objective"
                >
                </b-form-checkbox>
                <div class="w-100">
                  <b-badge class="ml-auto" :variant="getStatusVariant(objective.status)"> {{ objective.status }}</b-badge>
                  <h5 class="my-1">{{objective.title}}</h5>
                  <div class="d-flex align-items-center range-period">
                    <div class="d-flex flex-column">
                      <small>Period</small>
                      <h6 class="mb-0">{{objective.quarter}}, {{objective.year}}</h6>
                    </div>
                    <div class="vl mx-1"></div>
                    <div class="progress-value lined-progress">
                      <small>Overall Progress</small>
                      <div class="align-items-center sub-progress d-flex">
                      <b-progress :value="objective.overall_status.percentage" :max="100"></b-progress>
                      <span class="ml-50">{{objective.overall_status.percentage}}%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>              
            </ul>
          </div>
          
          <div class="import-q">
              <b-button variant="primary" pill @click="AddSelectedObjectives">Import to {{getCurrentQuarter()}}</b-button>
            </div>
      </div>
    </b-modal>
</template>

<script>
import $ from "jquery";
import { $themeConfig } from '@themeConfig';
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import axios from "@axios";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BListGroup,
  BListGroupItem,
  VBTooltip,
} from "bootstrap-vue";
import { required, email, numeric } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCard,
    BCardHeader,
    BCardTitle,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormCheckbox,
    BForm,
    BTabs,
    BTab,
  },
  props: ['getStatusVariant','AddtoCurrentOKR'],
  directives: {
    "b-tooltip": VBTooltip,
  },
  created() {    
    this.getPreviousOKRs();
  },
  data() {
    return {
      status:null,
      previousOKRs:[],
      selectedObjectives:[]
    };
  },
  methods: {
    getCurrentQuarter() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-indexed, so add 1

      // Calculate the quarter based on the current month
      if (currentMonth >= 1 && currentMonth <= 3) {
        return 'Q1';
      } else if (currentMonth >= 4 && currentMonth <= 6) {
        return 'Q2';
      } else if (currentMonth >= 7 && currentMonth <= 9) {
        return 'Q3';
      } else {
        return 'Q4';

      }
    },

    getPreviousOKRs() {
      
      let type = this.$route.params.type;
      if (type == "individual-okr") {
        this.type = 'Individual';
      }
      else if (type == "team-okr") {
        this.type = 'Team';
      }
      else if (type == "organisation-okr") {
        this.type = 'Organisation';
      }
      axios.get(`objectives/myPreviousOKRs?type=` + this.type)
        .then((res) => {
          if (res.data.success) {
            this.previousOKRs = res.data.data.previousOKRs;
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Oops! Unable to fetch Previous OKRs",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Unable to fetch Previous OKRs',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        }).catch((error) => {
          console.log(error);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Unable to fetch Previous OKRs",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Unable to fetch Previous OKRs',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    AddSelectedObjectives(){
      for (let index = 0; index < this.selectedObjectives.length; index++) {
        const objective = this.selectedObjectives[index];
        this.AddtoCurrentOKR(objective);
      }
      this.selectedObjectives = [];
    }
  },
};
</script>